import React, { useEffect, useRef, useState, useMemo } from "react"
import "./Spheralizer.styles.scss"
import { Button, Col, Row } from "antd"
import { animated, interpolate, useSprings } from "@react-spring/web"
import { CloseCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import getUncategorized from "../api/getUncategorized.api"
import dismissContact from "../api/dismissContact.api"
import addToSpheres from "../api/addToSpheres.api"
import { useDispatch, useSelector } from "react-redux"
import { fetchSpheres } from "../../../redux/Collections/Collections.actions"
import { updatePersonSpheres } from "../../../redux/People/People.actions"

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`

const SpheralizerPage = () => {
  const loadingSpheres = useSelector((state) => state.CollectionsState.loading)
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const [queue, setQueue] = useState([])
  const [currentPosition, setCurrentPosition] = useState(-1)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [filterTerm, setFilterTerm] = useState("")
  const [highlightedSphereIndex, setHighlightedSphereIndex] = useState(0)
  const [showNewSphereInput, setShowNewSphereInput] = useState(false)
  const nextButton = useRef()
  const dismissButton = useRef()
  const filterTermInput = useRef()
  const upButton = useRef()
  const downButton = useRef()
  const highlightedButton = useRef()
  const newSphereInput = useRef()
  const newSphereButton = useRef()
  const user = useSelector((state) => state.UserState)
  const canProceedRef = useRef(false)
  const [participantSpheres, setParticipantSpheres] = useState({})
  const prevCanProceedRef = useRef(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpheres())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (queue && queue.length > 0 && currentPosition < 0) {
      console.log("setting queue to ", currentPosition + 1)
      setCurrentPosition(currentPosition + 1)
    }
    // eslint-disable-next-line
  }, [queue])

  useEffect(() => {
    console.log("getting more uncategorized")
    const sources = "source: threads, sms and calls, phone book, contacts, linkedin"
    getUncategorized(currentPage, [sources], (data) => {
      console.log("Got the uncategorized!", data)
      setQueue([...queue, ...data.participants])
    })
    // eslint-disable-next-line
  }, [currentPage])

  const playAgain = () => {
    setQueue([])
    setCurrentPage(currentPage + 1)
    setCurrentPosition(-1)
  }

  const currentRecord =
    currentPosition > -1 && queue.length > currentPosition
      ? queue[queue.length - currentPosition - 1]
      : null

  const goBack = () => {
    if (currentPosition !== 0) {
      let index = queue.length - currentPosition
      setCurrentPosition(currentPosition - 1)

      // Get the previous contact
      const previousContact = queue[queue.length - currentPosition]
      if (previousContact) {
        // Update canProceedRef based on whether the previous contact has spheres
        const hasSpheres =
          participantSpheres[previousContact.id]?.spheres?.length > 0
        canProceedRef.current = hasSpheres
      }

      console.log(
        "ASKED TO GO BACK ",
        currentPosition,
        queue.length - currentPosition - 1
      )
      api.start((i) => {
        console.log(index, i)
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        return {
          ...to(i),
          delay: 100,
        }
      })

      // Explicitly blur the button immediately
      if (document.activeElement) {
        document.activeElement.blur()
      }
    }
  }

  const goNext = () => {
    setFilterTerm("")
    setHighlightedSphereIndex(0)
    setCurrentPosition(currentPosition + 1)

    // Reset canProceedRef for the new contact
    const nextContact = queue[queue.length - currentPosition - 2] // -2 because we're moving to the next one
    if (nextContact) {
      const hasSpheres = participantSpheres[nextContact.id]?.spheres?.length > 0
      canProceedRef.current = hasSpheres
    } else {
      canProceedRef.current = false
    }

    let index = queue.length - currentPosition - 1
    api.start((i) => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const x = window.innerWidth + 100 * Math.random()
      const rot = 360 * Math.random()
      const scale = 1.3
      return {
        x,
        rot,
        scale,
        delay: 100,
        config: { friction: 50, tension: 300 },
      }
    })

    // Explicitly blur the button immediately
    if (document.activeElement) {
      document.activeElement.blur()
    }
  }

  const dismiss = () => {
    if (currentRecord) {
      dismissContact(currentRecord.id, (data) => {
        console.log("Dimissed", data)
      })
    }
    goNext()

    // Explicitly blur the button immediately
    if (document.activeElement) {
      document.activeElement.blur()
    }
  }

  const addToSphere = (sphereId, newSphereName) => {
    setLoading(true)
    // Get the sphere object
    const sphere = newSphereName
      ? { title: newSphereName }
      : spheres.find((s) => s.id === sphereId)

    if (!currentRecord) {
      setLoading(false)
      return
    }

    const participantId = currentRecord.id
    const participantData = participantSpheres[participantId] || {
      person_id: currentRecord.person_id,
      spheres: [],
    }

    const existingSphereIndex = participantData.spheres.findIndex(
      (s) => s.id === sphere.id || s.title === sphere.title
    )

    if (participantData.person_id) {
      // We have the person cached, use dispatch to update spheres
      let updatedSpheres
      if (existingSphereIndex >= 0) {
        // Remove the sphere
        updatedSpheres = participantData.spheres.filter((s) => s.id !== sphere.id)
      } else {
        // Add the sphere
        updatedSpheres = [...participantData.spheres, sphere]
      }

      // Use Redux dispatch for existing person
      dispatch(
        updatePersonSpheres(participantData.person_id, { spheres: updatedSpheres })
      )

      // Update our local cache immediately while the API is processing
      setParticipantSpheres((prev) => ({
        ...prev,
        [participantId]: {
          ...participantData,
          spheres: updatedSpheres,
        },
      }))

      canProceedRef.current = updatedSpheres.length > 0
      setLoading(false)
    } else {
      // First time adding a sphere or no person cached yet
      addToSpheres(participantId, [sphere], (data) => {
        // Process API response
        if (data && data.people && data.people[0]) {
          const personId = data.people[0].person_id
          if (personId) {
            setParticipantSpheres((prev) => ({
              ...prev,
              [participantId]: {
                person_id: personId,
                spheres: [sphere],
              },
            }))
            canProceedRef.current = true
          }
        }
        setLoading(false)
      })
    }
  }

  const downHandler = ({ key, code }) => {
    if (document.activeElement !== newSphereInput.current) {
      if (/^[a-z]+$/.test(key)) {
        filterTermInput.current.value = filterTermInput.current.value + "" + key
        setFilterTerm(filterTermInput.current.value)
        setHighlightedSphereIndex(0)
      } else if (code === "Backspace") {
        filterTermInput.current.value = filterTermInput.current.value.slice(0, -1)
        setFilterTerm(filterTermInput.current.value)
      } else if (code === "Space") {
        // Only trigger next if we can proceed (have at least one sphere) AND Next button is not disabled
        if (
          canProceedRef.current &&
          nextButton.current &&
          !nextButton.current.disabled
        ) {
          // Protect against race conditions with a small delay
          setTimeout(() => {
            nextButton.current.click()
          }, 10)
        }
      } else if (code === "ShiftRight" || code === "ShiftLeft") {
        dismissButton.current.click()
      } else if (code === "ArrowUp") {
        downButton.current.click()
      } else if (code === "ArrowDown") {
        upButton.current.click()
      } else if (code === "Enter") {
        highlightedButton.current.click()
      }
    } else if (code === "Enter") {
      newSphereButton.current.click()
    }
  }

  const selectUp = () => {
    const newHSI = highlightedSphereIndex + 1
    setHighlightedSphereIndex(newHSI)
  }

  const selectDown = () => {
    setHighlightedSphereIndex(Math.max(highlightedSphereIndex - 1, 0))
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  const [props_, api] = useSprings(queue.length, (i) => ({
    ...to(i),
    from: from(i),
  })) // Create a bunch of springs using the helpers above

  console.log("Current record is ", currentRecord)

  const addNewSphere = () => {
    const newSphereName = newSphereInput.current.value
    if (!newSphereName) return

    addToSphere(null, newSphereName)
  }

  // Improve the effect to update sphere IDs when spheres are fetched
  useEffect(() => {
    // Only proceed if we have spheres and a current record
    if (!spheres || !spheres.length || !currentRecord) return

    const participantId = currentRecord.id
    const participantData = participantSpheres[participantId]

    // If we don't have any data for this participant or no spheres, skip
    if (
      !participantData ||
      !participantData.spheres ||
      !participantData.spheres.length
    )
      return

    // Check if any spheres need to be updated with proper IDs
    let hasUpdates = false
    const updatedSpheres = participantData.spheres.map((sphere) => {
      // If sphere has no ID but matches a title in our fetched spheres list
      if (!sphere.id && sphere.title) {
        const matchingSphere = spheres.find(
          (s) => s.title.toLowerCase() === sphere.title.toLowerCase()
        )

        if (matchingSphere) {
          hasUpdates = true
          return { ...sphere, id: matchingSphere.id }
        }
      }
      return sphere
    })

    // Only update state if we made changes
    if (hasUpdates) {
      console.log("Updating current participant spheres with proper IDs")
      setParticipantSpheres((prev) => ({
        ...prev,
        [participantId]: {
          ...participantData,
          spheres: updatedSpheres,
        },
      }))
    }
  }, [spheres, currentRecord])

  // Add a useEffect to watch for changes in canProceedRef and trigger the flash
  useEffect(() => {
    // Only flash when transitioning from false to true
    if (!prevCanProceedRef.current && canProceedRef.current && nextButton.current) {
      // Add flash class to trigger animation
      nextButton.current.classList.add("button-flash")

      // Remove the class after animation completes
      setTimeout(() => {
        if (nextButton.current) {
          nextButton.current.classList.remove("button-flash")
        }
      }, 1000)
    }

    // Update previous state
    prevCanProceedRef.current = canProceedRef.current
  }, [canProceedRef.current]) // This will run whenever canProceedRef.current changes

  // Add memoization for filtered spheres
  const filteredSpheres = useMemo(() => {
    return spheres.filter(
      (sphere) =>
        filterTerm.length === 0 ||
        sphere.title.match(new RegExp(`${filterTerm}`, "i"))
    )
  }, [spheres, filterTerm])

  // Update the highlighted sphere index when filtered list changes
  useEffect(() => {
    // Reset highlight index when filter changes but keep it in bounds
    setHighlightedSphereIndex(
      Math.min(highlightedSphereIndex, filteredSpheres.length - 1)
    )
  }, [filteredSpheres.length])

  // Add an effect to update canProceedRef whenever currentRecord changes
  useEffect(() => {
    if (currentRecord) {
      const hasSpheres = participantSpheres[currentRecord.id]?.spheres?.length > 0
      canProceedRef.current = hasSpheres
    } else {
      canProceedRef.current = false
    }
  }, [currentRecord, participantSpheres])

  return (
    <div className="game game-spheralizer">
      <Row className="nav">
        <a className="closeButton" href="/database">
          <CloseCircleOutlined />
        </a>
        <img
          src={`${process.env.PUBLIC_URL}/primary.svg`}
          alt="Relatable"
          className="logo"
        />
      </Row>
      <Row>
        <Col span="16">
          <Row>
            <div id="game-area">
              <div id="card-area">
                {currentPosition >= queue.length && (
                  <div className="restart-button">
                    <div onClick={playAgain} className="restart-button-button">
                      All done! Play again?
                    </div>
                  </div>
                )}
                {props_.map(({ x, y, rot, scale }, i) => (
                  <animated.div className="card" key={i} style={{ x, y }}>
                    {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
                    <animated.div
                      className="card-inner"
                      style={{
                        transform: interpolate([rot, scale], trans),
                      }}
                    >
                      <div className="card-body">
                        <div className="avatar-placeholder"></div>
                        {queue[i].full_name ? (
                          <h3>
                            {queue[i].full_name}
                            <span>{queue[i].email}</span>
                          </h3>
                        ) : (
                          <h3>{queue[i].email}</h3>
                        )}
                        <hr />

                        {i === queue.length - currentPosition - 1 && (
                          <div
                            className={`assigned-spheres ${
                              !participantSpheres[queue[i].id] ||
                              !participantSpheres[queue[i].id].spheres ||
                              participantSpheres[queue[i].id].spheres.length === 0
                                ? "empty"
                                : ""
                            } ${loading ? "loading" : ""}`}
                          >
                            <div className="sphere-tags">
                              {participantSpheres[queue[i].id]?.spheres?.length > 0
                                ? participantSpheres[queue[i].id].spheres.map(
                                    (sphere, idx) => (
                                      <span
                                        key={sphere.id || idx}
                                        className="sphere-tag"
                                      >
                                        {sphere.title}
                                      </span>
                                    )
                                  )
                                : null}
                            </div>
                          </div>
                        )}

                        <div className="conversation">
                          <h4>
                            {moment(queue[i].last_sent)
                              .tz(user.time_zone)
                              .format("MMM Do, YYYY")}
                          </h4>
                          <div className="subject">
                            {" "}
                            {queue[i].subject &&
                              queue[i].subject
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .substring(0, 50)}{" "}
                          </div>
                          <div className="body">
                            {" "}
                            {queue[i].body &&
                              queue[i].body
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .substring(0, 100)}
                            ...{" "}
                          </div>
                        </div>
                      </div>
                    </animated.div>
                  </animated.div>
                ))}
              </div>
            </div>
          </Row>
          <Row>
            <div className="controls">
              <Button
                onClick={(e) => {
                  goBack()
                  e.currentTarget.blur()
                }}
              >
                &lt; Go Back<small>&nbsp;</small>
              </Button>
              <Button
                onClick={(e) => {
                  dismiss()
                  e.currentTarget.blur()
                }}
                ref={dismissButton}
              >
                Dismiss<small>shift</small>
              </Button>
              <Button
                onClick={(e) => {
                  goNext()
                  e.currentTarget.blur()
                }}
                ref={nextButton}
                disabled={!canProceedRef.current}
                title={
                  !canProceedRef.current
                    ? "Add at least one sphere before proceeding"
                    : ""
                }
              >
                Next &gt;<small>spacebar</small>
              </Button>
            </div>
          </Row>
        </Col>
        <Col span="8">
          <Row className="filterRow">
            <label className="ant-col-10">type to filter: </label>
            <input
              type="text"
              name="filterTerm"
              className="ant-col-14"
              ref={filterTermInput}
              value={filterTerm}
              onChange={(e) => console.log(e)}
            />
          </Row>
          <small className="general-instructions">
            arrow keys up/down to move, enter/click to select
          </small>

          <Button onClick={selectUp} ref={upButton} className="hidden">
            selectUp
          </Button>
          <Button onClick={selectDown} ref={downButton} className="hidden">
            selectDown
          </Button>
          {!loadingSpheres && spheres && spheres.length > 0 ? (
            <>
              <div className="spheresSection">
                {filteredSpheres.map((sphere, index) => {
                  // Check if this sphere is already assigned to the contact
                  const isAssigned =
                    currentRecord &&
                    participantSpheres[currentRecord.id]?.spheres.some(
                      (s) => s.id === sphere.id
                    )

                  return (
                    <Button
                      key={sphere.id}
                      className={`option-button ${
                        isAssigned ? "sphere-assigned" : ""
                      }`}
                      onClick={() => addToSphere(sphere.id)}
                      ref={
                        index === highlightedSphereIndex ? highlightedButton : null
                      }
                      type={
                        isAssigned
                          ? "primary"
                          : index === highlightedSphereIndex
                          ? "primary"
                          : "default"
                      }
                      disabled={loading}
                    >
                      <div className="element">
                        <div className="truncate">{sphere.title}</div>
                        {isAssigned && (
                          <span className="sphere-assigned-icon">✓</span>
                        )}
                      </div>
                    </Button>
                  )
                })}
                {showNewSphereInput ? (
                  <div className="addNewSphere">
                    <input
                      placeholder="New Sphere"
                      className="addNewSphere_Input option-button"
                      ref={newSphereInput}
                      autoFocus={true}
                    />
                    <Button
                      className="addNewSphere_Button option-button"
                      onClick={addNewSphere}
                      ref={newSphereButton}
                    >
                      Add
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="option-button"
                    onClick={() => setShowNewSphereInput(true)}
                    ref={
                      filteredSpheres.length === highlightedSphereIndex
                        ? highlightedButton
                        : null
                    }
                    type={
                      filteredSpheres.length === highlightedSphereIndex
                        ? "primary"
                        : "default"
                    }
                  >
                    Add New Sphere
                  </Button>
                )}
              </div>
            </>
          ) : (
            <h3 style={{ marginLeft: "20px" }}>Loading Spheres...</h3>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default SpheralizerPage
